.card-info-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .mark {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        z-index: -1;
    }
    .model {
        width: 82.75862068965517vw;
        // height: 325px;
        height: 115.5172413793103vw;
        .info-frame {
            width: 82.75862068965517vw;
            // height: 280px;
            height: 100vw;
            background-size: 100% 100%;
            background-repeat: no-repeat;
            display: flex;
            flex-direction: column;
            align-items: center;
            .tips {
                margin-top: 5.17241379310345vw;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 41.37931034482759vw;
                height: 6.20689655172414vw;
                color: rgba(255, 255, 255, 1);
                border-radius: 10px;
                background-color: rgba(101, 179, 255, 1);
                font-size: 3.44827586206897vw;
                font-weight: 400;
            }
            .title {
                width: 68.96551724137931vw;
                text-align: center;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-size: 4.82758620689655vw;
                font-weight: bold;
                color: rgba(17, 17, 17, 1);
                margin-top: 3.44827586206897vw;
                flex-shrink: 0;
            }
            .author {
                margin-top: 1.72413793103448vw;
                font-size: 3.44827586206897vw;
                color: rgba(17, 17, 17, 1);
                font-weight: 400;
                flex-shrink: 0;
            }
            .code {
                width: 31.03448275862069vw;
                height: 31.03448275862069vw;
                margin-top: 3.44827586206897vw;
                flex-shrink: 0;
            }
            .summary {
                margin-top: 1.72413793103448vw;
                width: calc(100% - 10.3448275862069vw);
                font-weight: 400;
                font-size: 3.44827586206897vw;
                color: rgba(51, 51, 51, 1);
                flex-shrink: 0;
                display: -webkit-box;
                text-overflow: clip;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                overflow: hidden;
            }
            .summary-after {
                @extend .summary;
                margin-top: 0;
                width: 45.51724137931034vw;
                margin-left: 4.13793103448276vw;
            }
        }
        .close {
            width: 82.75862068965517vw;
            height: 10.3448275862069vw;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 5.17241379310345vw;
            background: #6bb6ff;
            border-radius: 1.72413793103448vw;
            font-weight: 500;
            font-size: 4.82758620689655vw;
            line-height: 5.17241379310345vw;
            align-items: center;
            color: #ffffff;
        }
    }
}
