.book-card-container {
    width: 23.76599634369287vw;
    height: 33.27239488117002vw;
    margin-bottom: 5.484460694698355vw;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    border-radius: 5px;
    position: relative;
    .title-container{
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        left: 0;
        height: 7.312614259597806vw;
        width: 100%;
        border-radius: 0px 0px 5px 5px;
        &.blue {
            background-color: #a8ffe0;
        }
        &.yellow {
            background-color: #f1ff98;
        }
        &.organ {
            width: calc(100% - 7.58620689655172vw);
            padding: 0px 3.79310344827586vw;
            background-color: #f9b738;
        }
    }
    .title {
        // padding-top: 1.37931034482759vw;
        // height: calc(13.79310344827586vw - 1.37931034482759vw);
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        color: rgba(17, 17, 17, 1);
        font-size: 2.925045703839122vw;
        font-weight: 400;
    }
}
