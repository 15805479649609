.bookinfo-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.bookinfo-container .reciprocal {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 4.87012987012987vw;
    top: 4.87012987012987vw;
    padding-left: 2.435064935064935vw;
    padding-right: 2.435064935064935vw;
    height: 5.681818181818182vw;
    background-color: rgba(255, 255, 255, 1);
    color: rgba(17, 17, 17, 1);
    border-radius: 20px;
    font-weight: bold;
    font-size: 2.222077922077922vw;
}

.bookinfo-container .mark {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
}

.bookinfo-container .bookinfo-card {
    width: 58.44155844155844vw;
    padding-top: 3.246753246753247vw;
    padding-bottom: 4.545454545454545vw;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.bookinfo-card .header-tips {
    width: 22.88961038961039vw;
    height: 4.058441558441558vw;
    background-color: rgba(142, 1, 18, 1);
    color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.948051948051948vw;
    border-radius: 15px;
}

.bookinfo-card .book-title {
    text-align: center;
    width: 60%;
    margin-top: 2.435064935064935vw;
    font-size: 2.597402597402597vw;
    font-weight: bold;
    color: rgba(17, 17, 17, 1);

    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    overflow: hidden;
    -webkit-box-orient: vertical;
    word-break: break-all;
}

.bookinfo-card .book-author {
    margin-top: 0.8116883116883117vw;
    font-size: 1.948051948051948vw;
    color: rgba(17, 17, 17, 1);
}

.bookinfo-card .book-qrcode {
    margin-top: 2.435064935064935vw;
}

.bookinfo-card .book-summary {
    text-indent: 2em;
    margin-top: 2.435064935064935vw;
    font-size: 1.948051948051948vw;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    width: calc(100% - 6.49vw);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
}

.bookinfo-container .tips {
    margin-top: 1.623376623376623vw;
    background-color: rgba(255, 255, 255, 1);
    height: 4.87012987012987vw;
    width: 43.83116883116883vw;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.272727272727273vw;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
}