:root {
    --small-scale: 0.5;
    --centre-scale: 0.7;
}
.light-disk-container {
    position: relative;
    width: 160px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    &.small {
        width: calc(160px * var(--small-scale));
        height: calc(150px * var(--small-scale));
    }
    &.centre {
        width: calc(160px * var(--centre-scale));
        height: calc(150px * var(--centre-scale));
    }
    .light-disk-img {
        flex-shrink: 0;
        position: absolute;
        width: 160px;
        height: 150px;
        z-index: 0;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        &.small {
            width: calc(160px * var(--small-scale));
            height: calc(150px * var(--small-scale));
        }
        &.centre {
            width: calc(160px * var(--centre-scale));
            height: calc(150px * var(--centre-scale));
        }
    }
    .cover-img {
        flex-shrink: 0;
        position: absolute;
        z-index: 1;
        width: 72px;
        height: 72px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        border-radius: 50%;
        margin-left: -5px;
        border: 3px solid #2f2e2e;
        &.small {
            width: calc(72px * var(--small-scale));
            height: calc(72px * var(--small-scale));
        }
        &.centre {
            width: calc(72px * var(--centre-scale));
            height: calc(72px * var(--centre-scale));
        }
    }
    .light-disk-son-img {
        flex-shrink: 0;
        position: absolute;
        width: 10px;
        height: 10px;
        z-index: 2;
        margin-left: -5px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .music-item-bottom {
        flex-shrink: 0;
        position: absolute;
        bottom: 0px;
        width: calc(160px - 40px);
        height: 30px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 700;
        color: rgba(34, 34, 34, 1);

        padding: 0px 20px;

        display: block;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        background-size: 100% 100%;
        background-repeat: no-repeat;
        z-index: 10;
        line-height: 30px;

        &.small {
            width: calc(calc(160px - 40px) * var(--small-scale));
            height: calc(30px * var(--small-scale));
            padding: 0px calc(20px * var(--small-scale));
            line-height: calc(30px * var(--small-scale));
        }
        &.centre {
            width: calc(calc(160px - 40px) * var(--centre-scale));
            height: calc(30px * var(--centre-scale));
            padding: 0px calc(20px * var(--centre-scale));
            line-height: calc(30px * var(--centre-scale));
        }
    }
}
