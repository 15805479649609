.waterfall_flow_container {
    position: relative;
    width: 100vw;
    height: calc(351.0054844606947vw - 5.484460694698355vw - 5.484460694698355vw);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding-top: 5.484460694698355vw;
    padding-bottom: 5.484460694698355vw;
    display: flex;
    justify-content: center;
    user-select: none !important;
    -webkit-user-select: none !important;
    .cloud {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1000;
        height: 45.53747714808044vw;
        width: 100vw;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .content {
        width: 85.37477148080439vw;
        height: 340.036563071298;
        position: relative;
    }
}
