.catalogue-main {
    position: absolute;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    margin-top: -110px;
    border-radius: 40px;
    overflow-y: scroll;
}

:root {
    --mw-700: 1.2;
    --mw-600: 1.4;
    --mw-500: 1.6;
    --mw-400: 1.8;
    --mw-300: 2;
}

.catalogue-main .catalogue-head {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 70px;
    padding-right: 50px;
}

.catalogue-head .catalogue-label {
    font-size: 48px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
}

.catalogue-head .catalogue-opertion {
    display: flex;
    cursor: pointer;
}

.catalogue-head .catalogue-opertion:hover {
    opacity: 0.9;
}

.catalogue-head .catalogue-opertion:active {
    opacity: 0.6;
}

.catalogue-opertion .opertion-icon {
    width: 57px;
    height: 34px;
}

.catalogue-opertion .opertion-label {
    font-weight: 400;
    font-size: 24px;
    color: rgba(153, 153, 153, 1);
    margin-left: 15px;
}

.catalogue-main .catalogue-content {
    width: calc(100% - 120px);
    padding-left: 80px;
    padding-bottom: 100px;
}

.catalogue-content .list-item {
    font-weight: 400;
    font-size: 30px;
    color: rgba(51, 51, 51, 1);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 29px;
    cursor: pointer;
}

.catalogue-content .list-item-first {
    font-weight: 400;
    font-size: 30px;
    color: rgba(51, 51, 51, 1);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-top: 35px;
    cursor: pointer;
}

@media screen and (max-width:700px) {
    .catalogue-main .catalogue-head {
        margin-top: calc(30px / var(--mw-700));
        padding-left: calc(70px / var(--mw-700));
        padding-right: calc(50px / var(--mw-700));
    }


    .catalogue-head .catalogue-label {
        font-size: calc(48px / var(--mw-700));
    }

    .catalogue-opertion .opertion-icon {
        width: calc(57px / var(--mw-700));
        height: calc(34px /var(--mw-700));
    }

    .catalogue-opertion .opertion-label {
        font-size: calc(24px / var(--mw-700));
        margin-left: calc(15px / var(--mw-700));
    }

    .catalogue-main .catalogue-content {
        width: calc(calc(100% - 120px) / var(--mw-700));
        padding-left: calc(80px / var(--mw-700));
        padding-bottom: calc(100px / var(--mw-700));
    }

    .catalogue-content .list-item {
        font-size: calc(30px / var(--mw-700));
        margin-top: calc(29px / var(--mw-700));
    }

    .catalogue-content .list-item-first {
        font-size: calc(30px / var(--mw-700));
        margin-top: calc(35px / var(--mw-700));
    }
}

@media screen and (max-width:600px) {
    .catalogue-main .catalogue-head {
        margin-top: calc(30px / var(--mw-600));
        padding-left: calc(70px / var(--mw-600));
        padding-right: calc(50px / var(--mw-600));
    }


    .catalogue-head .catalogue-label {
        font-size: calc(48px / var(--mw-600));
    }

    .catalogue-opertion .opertion-icon {
        width: calc(57px / var(--mw-600));
        height: calc(34px /var(--mw-600));
    }

    .catalogue-opertion .opertion-label {
        font-size: calc(24px / var(--mw-600));
        margin-left: calc(15px / var(--mw-600));
    }

    .catalogue-main .catalogue-content {
        width: calc(calc(100% - 120px) / var(--mw-600));
        padding-left: calc(80px / var(--mw-600));
        padding-bottom: calc(100px / var(--mw-600));
    }

    .catalogue-content .list-item {
        font-size: calc(30px / var(--mw-600));
        margin-top: calc(29px / var(--mw-600));
    }

    .catalogue-content .list-item-first {
        font-size: calc(30px / var(--mw-600));
        margin-top: calc(35px / var(--mw-600));
    }
}

@media screen and (max-width:500px) {
    .catalogue-main .catalogue-head {
        margin-top: calc(30px / var(--mw-500));
        padding-left: calc(70px / var(--mw-500));
        padding-right: calc(50px / var(--mw-500));
    }


    .catalogue-head .catalogue-label {
        font-size: calc(48px / var(--mw-500));
    }

    .catalogue-opertion .opertion-icon {
        width: calc(57px / var(--mw-500));
        height: calc(34px /var(--mw-500));
    }

    .catalogue-opertion .opertion-label {
        font-size: calc(24px / var(--mw-500));
        margin-left: calc(15px / var(--mw-500));
    }

    .catalogue-main .catalogue-content {
        width: calc(calc(100% - 120px) / var(--mw-500));
        padding-left: calc(80px / var(--mw-500));
        padding-bottom: calc(100px / var(--mw-500));
    }

    .catalogue-content .list-item {
        font-size: calc(30px / var(--mw-500));
        margin-top: calc(29px / var(--mw-500));
    }

    .catalogue-content .list-item-first {
        font-size: calc(30px / var(--mw-500));
        margin-top: calc(35px / var(--mw-500));
    }
}

@media screen and (max-width:400px) {
    .catalogue-main .catalogue-head {
        margin-top: calc(30px / var(--mw-400));
        padding-left: calc(70px / var(--mw-400));
        padding-right: calc(50px / var(--mw-400));
    }


    .catalogue-head .catalogue-label {
        font-size: calc(48px / var(--mw-400));
    }

    .catalogue-opertion .opertion-icon {
        width: calc(57px / var(--mw-400));
        height: calc(34px /var(--mw-400));
    }

    .catalogue-opertion .opertion-label {
        font-size: calc(24px / var(--mw-400));
        margin-left: calc(15px / var(--mw-400));
    }

    .catalogue-main .catalogue-content {
        width: calc(calc(100% - 0px) / var(--mw-400));
        padding-left: calc(80px / var(--mw-400));
        padding-bottom: calc(100px / var(--mw-400));
    }

    .catalogue-content .list-item {
        font-size: calc(30px / var(--mw-400));
        margin-top: calc(29px / var(--mw-400));
    }

    .catalogue-content .list-item-first {
        font-size: calc(30px / var(--mw-400));
        margin-top: calc(35px / var(--mw-400));
    }
}

@media screen and (max-width:300px) {
    .catalogue-main .catalogue-head {
        margin-top: calc(30px / var(--mw-300));
        padding-left: calc(70px / var(--mw-300));
        padding-right: calc(50px / var(--mw-300));
    }


    .catalogue-head .catalogue-label {
        font-size: calc(48px / var(--mw-300));
    }

    .catalogue-opertion .opertion-icon {
        width: calc(57px / var(--mw-300));
        height: calc(34px /var(--mw-300));
    }

    .catalogue-opertion .opertion-label {
        font-size: calc(24px / var(--mw-300));
        margin-left: calc(15px / var(--mw-300));
    }

    .catalogue-main .catalogue-content {
        width: calc(calc(100% - 120px) / var(--mw-300));
        padding-left: calc(80px / var(--mw-300));
        padding-bottom: calc(100px / var(--mw-300));
    }

    .catalogue-content .list-item {
        font-size: calc(30px / var(--mw-300));
        margin-top: calc(29px / var(--mw-300));
    }

    .catalogue-content .list-item-first {
        font-size: calc(30px / var(--mw-300));
        margin-top: calc(35px / var(--mw-300));
    }
}