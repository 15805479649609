.player-catalogue-main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.5);
    z-index: 999999;
    overflow: hidden;
}

.player-catalogue-main .mark{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(51, 51, 51, 0.5);
}

.player-catalogue-main .player-catalogue-box {
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(100vw - .625rem - .625rem);
    height: 60vh;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 30px 30px 0px 0px;
    padding-left: .625rem;
    padding-right: .625rem;
    overflow-y: scroll;
}

.player-catalogue-box .header {
    width: calc(100vw - .625rem - .625rem);
    height: 9.765625vw;
}

.header .label {
    margin-top: 1.5625vw;
    font-weight: bold;
    font-size: 1.125rem;
    color: rgba(51, 51, 51, 1);
}

.header .opertion {
    margin-top: 2.34375vw;
    width: calc(100vw - .625rem - .625rem);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.opertion .catalogue-player {
    width: 1.5625rem;
    height: 1.25rem;
}

.opertion .order-main {
    height: 3.203125vw;
    display: flex;
    align-items: center;
    margin-right: .625rem;
    flex-shrink: 0;
}

.order-main .order-png {
    width: 1.625rem;
    height: 1.0938rem;
}

.order-main .order-title {
    margin-right: -0.78125vw;
    margin-left: 0.78125vw;
    font-size: 1.125rem;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.player-catalogue-box .player-catalogue-content {
    position: relative;
    top: 1.25rem;
}

.player-catalogue-content .catalogue-item {
    margin-top: .9375rem;
    display: flex;
    align-items: center;
}

.catalogue-item .catalogue-playing {
    width: 3.125vw;
    height: 3.125vw;
}

.catalogue-item .catalogue-title-active {
    margin-left: 2.34375vw;
    font-size: 1.125rem;
    color: rgba(105, 157, 223, 1);
}

.catalogue-item .catalogue-title {
    font-size: 1.0625rem;
    color: rgba(102, 102, 102, 1);
}

.player-catalogue-content .limit {
    height: 6.25vw;
    width: calc(100vw - 9.296875vw - 10.9375vw);
}