.waterfall-model {
    height: 404.3636363636364vw;
    width: 85.37477148080439vw;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    flex-shrink: 0;
    &::-webkit-scrollbar {
        display: none !important;
    }
    .waterfall-container {
        width: 85.37477148080439vw;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-shrink: 0;
        .row {
            display: flex;
            justify-content: space-between;
            width: 85.37477148080439vw;
            flex-shrink: 0;
        }
    }
}
