.power-fall-model {
    height: 221.5909090909091vw;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    flex-shrink: 0;
    &::-webkit-scrollbar {
        display: none !important;
    }
    .power-fall-container {
        width: calc(100vw - 16.23376623376623vw);
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        flex-shrink: 0;
        .row {
            margin-top: 1.25rem;
            display: flex;
            justify-content: space-between;
            width: 100%;
            flex-shrink: 0;
        }
    }
}
