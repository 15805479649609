.power-fall-book-card-container {
    width: 24.35064935064935vw;
    height: 31.81818181818182vw;
    position: relative;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    border-radius: 5px;
    flex-shrink: 0;
}

.power-fall-book-card-container .bottom-bar {
    position: absolute;
    bottom: 0;
    left: 0;
    height: calc(6.493506493506494vw - 0.974025974025974vw - 1.298701298701299vw);
    width: calc(24.35064935064935vw - 3.571428571428571vw);
    background-color: #F9B738;
    text-align: center;
    border-radius: 0px 0px 5px 5px;
    padding: 0vw 1.785714285714286vw;
    padding-top: 0.974025974025974vw;
    padding-bottom: 1.298701298701299vw;
    flex-shrink: 0;
}

.bottom-bar .text {
    color: #111111;
    font-size: 1.785714285714286vw;
    font-weight: 400;
    overflow: hidden;
    text-overflow: clip;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    flex-shrink: 0;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
}