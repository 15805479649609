.music-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .music-header{
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 776px;
        z-index: 20;
    }
    .content {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        top: 600px;
        width: calc(100% - 69px - 69px);
        height: calc(100vh - 629px);
        overflow-y: scroll;
        padding: 0px 69px;
        .music-limit{
            width: 100px;
            height: 200px;
        }
    }
    .music-bottom{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 168px;
        z-index: 10;
    }
}

.layout-contaienr {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    .layout-content{
        position: relative;
        top: 6px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }
    .layout-bottom {
        width: 517px;
        height: 12px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
}
