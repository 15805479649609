.right-card-container{
    margin-top: 252px;
    position: relative;
    width: 243.74px;
    height: 618px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .cultural_card_bg{
        position: relative;
        z-index: 10;
        width: 195px;
        height: 618px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .cultural_head_img{
        position: absolute;
        width: 237.97px;
        height: 258.76px;
        top: 93.54px;
        left: 0px;
    }
    .user-info{
        position: absolute;
        width: 165px;
        height: 258px;
        top: 359px;
        right: 5px;
        .name{
            font-size: 26px;
            font-weight: 700;
            color: rgba(180, 145, 102, 1);
        }
        .limit{
            margin-top: 5px;
            width: 38px;
            height: 3px;
            background-color: rgba(180, 145, 102, 1);
        }
        .honor{
            margin-top: 5px;
            font-size: 18px;
            font-weight: 600;
            color: rgba(180, 145, 102, 1);
        }
        .description{
            margin-top: 5px;
            font-size: 13px;
            font-weight: 400;
            color: rgba(26, 28, 22, 1);
            line-height: 26px;
        }
    }
}