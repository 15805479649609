.player-main {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.player-main header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 3.125rem;
    width: 100%;
    background-color: #FFFFFF;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
}

header .header-back {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 13.125vw;
    flex-shrink: 0;
}

.header-back .back {
    width: 1.125rem;
    height: 1.125rem;
}

header .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: .8rem;
    color: #222;
}

header .header-home {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 13.125vw;
    flex-shrink: 0;
}

.header-home .home {
    width: 1.4375rem;
    height: 1.4375rem;
}

.player-main .navigation-title {
    font-size: 3.8125vw;
    font-weight: 500;
    color: rgba(17, 17, 17, 1);
    white-space: nowrap;
}

.player-main .player-chaterName {
    margin-top: 2.5vw;
    font-size: 20px;
    color: rgba(51, 51, 51, 0.8);
}

.player-main .player-box {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: calc(100% * (965 / 1280));
}

.player-box .player-cover-main {
    width: 4.4rem;
    height: 5.86667rem;
    margin-top: .8rem;
    margin-bottom: .6rem;
    flex-shrink: 0;
}

.player-cover-main .player-cover {
    width: 100%;
    height: 100%;
    background-color: black;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.player-box .rotate-animtion {
    /* animation: rotate-animtion 10s infinite linear; */
}

.player-box .player-diversity-name {
    color: #222;
    font-size: .8rem;
    line-height: 1.1rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: .2rem;
}

@keyframes rotate-animtion {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}