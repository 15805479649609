.player-panel-mains {
    position: relative;
    margin-top: 1.4rem;
    width: 100%;
    height: 12.5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-shrink: 0;
}

.player-panel-mains .catalogue-player-btn {
    width: 4.0625vw;
    height: 3.59375vw;
    flex-shrink: 0;
}

.player-panel-mains .last-player {
    width: 8.28125vw;
    height: 8.28125vw;
    flex-shrink: 0;
}

.player-panel-mains .play-player {
    width: 12.5vw;
    height: 12.5vw;
    flex-shrink: 0;
}

.player-panel-mains .collect-player {
    width: 4.6875vw;
    height: 3.7875vw;
    flex-shrink: 0;
}

.collect-player .collect-number {
    position: relative;
    top: -2.5vw;
    left: 5vw;
    font-size: 2.34375vw;
    font-weight: 400;
    color: rgba(102, 102, 102, 1);
    flex-shrink: 0;
}

.player-panel-mains .background {
    background-size: 100% 100%;
    background-repeat: no-repeat;
}

.btns:hover {
    opacity: 0.9;
}

.btns:active {
    opacity: 0.6;
}