.book-card-main {
    width: 100%;
    height: 100%;
    background-color: rgb(216, 216, 216);
    padding-bottom: 100px;
}

:root {
    --mw-700: 1.2;
    --mw-600: 1.4;
    --mw-500: 1.6;
    --mw-400: 1.8;
    --mw-300: 2;
    --gradient-color: rgba(0, 0, 0, 0);
}

.book-card-main .book-card-box {
    height: 340.78px;
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: center; */
}

.book-card-box .book-card-block {
    width: 100%;
    margin-top: -20px;
    display: flex;
    align-items: center;
    /* justify-content: space-evenly; */
}

.book-card-box .cover {
    margin-left: 6vw;
    width: 185px;
    height: 262px;
    border-radius: 5px;
}

.book-card-box .info-box {
    margin-left: 5.33vw;
    display: flex;
    flex-direction: column;
    width: 45%;
}

.info-box .title {
    font-size: 50px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
}

.info-box .author {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 400;
    color: rgba(153, 153, 153, 1);
}

.info-box .desc {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
    text-indent: 2em;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow-y: scroll;
}

.qrcode-main {
    margin-left: 5.33vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 153px;
    height: 187px;
}

.qrcode-main .qrcode-logo {
    width: 145.16px;
    height: 145px;
}

.qrcode-main .qrcode-tips {
    margin-top: 15px;
    font-size: 18px;
    font-weight: 400;
    color: rgba(51, 51, 51, 1);
}

@media screen and (max-width :700px) and (min-width:600px) {
    .book-card-main .book-card-box {
        height: calc(340.78px / var(--mw-700));
    }

    .book-card-box .cover {
        width: calc(185px / var(--mw-700));
        height: calc(262px / var(--mw-700));
    }

    .info-box .title {
        font-size: calc(50px / var(--mw-700));
    }

    .info-box .author {
        margin-top: calc(20px / var(--mw-700));
        font-size: calc(20px / var(--mw-700));
    }

    .info-box .desc {
        margin-top: calc(20px / var(--mw-700));
        font-size: calc(20px / var(--mw-700));
    }

    .qrcode-main {
        width: calc(153px / var(--mw-700));
        height: calc(187px / var(--mw-700));
    }

    .qrcode-main .qrcode-logo {
        width: calc(145.16px / var(--mw-700));
        height: calc(145px / var(--mw-700));
    }

    .qrcode-main .qrcode-tips {
        margin-top: calc(15px / var(--mw-700));
        font-size: calc(18px / var(--mw-700));
    }
}

@media screen and (max-width :600px) and (min-width:500px) {
    .book-card-main .book-card-box {
        height: calc(340.78px / var(--mw-600));
    }

    .book-card-box .cover {
        width: calc(185px / var(--mw-600));
        height: calc(262px / var(--mw-600));
    }

    .info-box .title {
        font-size: calc(50px / var(--mw-600));
    }

    .info-box .author {
        margin-top: calc(20px / var(--mw-600));
        font-size: calc(20px / var(--mw-600));
    }

    .info-box .desc {
        margin-top: calc(20px / var(--mw-600));
        font-size: calc(20px / var(--mw-600));
    }

    .qrcode-main {
        width: calc(153px / var(--mw-600));
        height: calc(187px / var(--mw-600));
    }

    .qrcode-main .qrcode-logo {
        width: calc(145.16px / var(--mw-600));
        height: calc(145px / var(--mw-600));
    }

    .qrcode-main .qrcode-tips {
        margin-top: calc(15px / var(--mw-600));
        font-size: calc(18px / var(--mw-600));
    }
}

@media screen and (max-width :500px) and (min-width:400px) {
    .book-card-main .book-card-box {
        height: calc(340.78px / var(--mw-500));
    }

    .book-card-box .cover {
        width: calc(185px / var(--mw-500));
        height: calc(262px / var(--mw-500));
    }

    .info-box .title {
        font-size: calc(50px / var(--mw-500));
    }

    .info-box .author {
        margin-top: calc(20px / var(--mw-500));
        font-size: calc(20px / var(--mw-500));
    }

    .info-box .desc {
        margin-top: calc(20px / var(--mw-500));
        font-size: calc(20px / var(--mw-500));
    }

    .qrcode-main {
        width: calc(153px / var(--mw-500));
        height: calc(187px / var(--mw-500));
    }

    .qrcode-main .qrcode-logo {
        width: calc(145.16px / var(--mw-500));
        height: calc(145px / var(--mw-500));
    }

    .qrcode-main .qrcode-tips {
        margin-top: calc(15px / var(--mw-500));
        font-size: calc(18px / var(--mw-500));
    }
}

@media screen and (max-width :400px) and (min-width:300px) {
    .book-card-main .book-card-box {
        height: calc(340.78px / var(--mw-400));
    }

    .book-card-box .cover {
        width: calc(185px / var(--mw-400));
        height: calc(262px / var(--mw-400));
    }

    .info-box .title {
        font-size: calc(50px / var(--mw-400));
    }

    .info-box .author {
        margin-top: calc(20px / var(--mw-400));
        font-size: calc(20px / var(--mw-400));
    }

    .info-box .desc {
        margin-top: calc(20px / var(--mw-400));
        font-size: calc(20px / var(--mw-400));
    }

    .qrcode-main {
        width: calc(153px / var(--mw-400));
        height: calc(187px / var(--mw-400));
    }

    .qrcode-main .qrcode-logo {
        width: calc(145.16px / var(--mw-400));
        height: calc(145px / var(--mw-400));
    }

    .qrcode-main .qrcode-tips {
        margin-top: calc(15px / var(--mw-400));
        font-size: calc(18px / var(--mw-400));
    }
}

@media screen and (max-width :300px) {
    .book-card-main .book-card-box {
        height: calc(340.78px / var(--mw-300));
    }

    .book-card-box .cover {
        width: calc(185px / var(--mw-300));
        height: calc(262px / var(--mw-300));
    }

    .info-box .title {
        font-size: calc(50px / var(--mw-300));
    }

    .info-box .author {
        margin-top: calc(20px / var(--mw-300));
        font-size: calc(20px / var(--mw-300));
    }

    .info-box .desc {
        margin-top: calc(20px / var(--mw-300));
        font-size: calc(20px / var(--mw-300));
    }

    .qrcode-main {
        width: calc(153px / var(--mw-300));
        height: calc(187px / var(--mw-300));
    }

    .qrcode-main .qrcode-logo {
        width: calc(145.16px / var(--mw-300));
        height: calc(145px / var(--mw-300));
    }

    .qrcode-main .qrcode-tips {
        margin-top: calc(15px / var(--mw-300));
        font-size: calc(18px / var(--mw-300));
    }
}