.cultural-container {
    width: calc(100vw - 40px);
    height: 100vh;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    padding: 0px 20px;
    .cultural-title {
        text-align: right;
        position: absolute;
        top: 30px;
        right: 30px;
        width: 235px;
        height: 102px;
        font-size: 30px;
        font-weight: 900;
        color: rgba(171, 138, 97, 1);
        line-height: 50px;
    }
    .cultural-content {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        overflow-y: scroll;
        position: relative;
        top: 135px;
    }
    .cultural-footer {
        z-index: 20;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 264.5px;
    }
}
