.info-toast-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 30;
    .mark {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.6);
        backdrop-filter: blur(3px);
        -webkit-backdrop-filter: blur(3px);
        z-index: -1;
    }
    .info-toast-main {
        padding: 14px 13px;
        background-color: rgba(188, 155, 111, 1);
        border-radius: 5px;
        .info-toast-box {
            width: calc(314px - 18px - 18px);
            padding: 0px 18px;
            padding-top: 12px;
            background-color: rgba(255, 255, 255, 1);
            border-radius: 5px;
            display: flex;
            flex-direction: column;
            align-items: center;
            .box-tips {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                color: rgba(255, 255, 255, 1);
                width: 156px;
                height: 25px;
                border-radius: 12px;
                background-color: rgba(188, 155, 111, 1);
                font-size: 13px;
                font-weight: 400;
            }
            .box-title {
                margin-top: 12px;
                font-size: 16px;
                font-weight: bold;
                color: rgba(17, 17, 17, 1);
            }
            .box-honor {
                margin-top: 5px;
                font-size: 11px;
                color: rgba(17, 17, 17, 1);
                font-weight: 400;
            }
            .box-qrcode {
                margin-top: 10px;
                width: 100px;
                height: 100px;
            }
            .box-description {
                margin-top: 10px;
                margin-bottom: 20px;
            }
        }
    }
    .toast-close-btn {
        margin-top: 20px;
        width: 340px;
        height: 40px;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: rgba(255, 255, 255, 1);
        font-size: 16px;
        background-color: rgba(188, 155, 111, 1);
    }
}
