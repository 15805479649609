.bookinfo-main {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: rgba(51, 51, 51, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.bookinfo-main .mark {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    width: 100%;
    z-index: 0;
}

.bookinfo-main .box {
    position: absolute;
    width: 84.375vw;
    height: 75vh;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 20px;
    padding-left: 3.125vw;
}

.box .info-main {
    margin-top: 7.5vh;
    display: flex;
    width: 78.125vw;
    height: 45vh;
}

.info-main .cover {
    width: 19.921875vw;
    height: 45vh;
}

.info-main .info-box {
    width: 37.03125vw;
    margin-left: 3.515625vw;
}

.info-box .title {
    font-size: 26px !important;
    color: rgba(51, 51, 51, 1);
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.info-box .author {
    font-size: 16px;
    color: rgba(153, 153, 153, 1);
    font-weight: 400;
    margin-top: 0.375vh !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
}

.info-box .summary {
    margin-top: 2.875vh !important;
    font-size: 16px !important;
    width: 100%;
    /* height: calc(100% * 0.5638888888888889); */
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
}

.info-main .qrcode-main {
    margin-left: 6.71875vw;
    height: 100%;
}

.qrcode-main #qrmains {
    margin-top: 10.75vh;
    width: 10.9375vw;
    height: 10.9375vw;
}

.qrcode-main .bookinfo-tips {
    margin-top: 1.25vh;
    font-size: 18px;
    color: rgba(102, 102, 102, 1);
    font-weight: 400;
}

.box .start-button {
    margin-top: 7.5vh;
    width: 100%;
    display: flex;
    justify-content: center;
}

.start-button .btns {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    color: rgba(255, 255, 255, 1);
    font-size: 20px;
    position: absolute;
    width: 18.75vw;
    height: 7.5vh;
    background: linear-gradient(94.32deg, #49CD76 0%, #42D887 100%);
}

@media screen and (min-width:1800px) {
    .info-box .title {
        font-size: 50px !important;
        color: rgba(51, 51, 51, 1);
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    
    .info-box .author {
        font-size: 30px !important;
        color: rgba(153, 153, 153, 1);
        font-weight: 400;
        margin-top: 0.375vh !important;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    
    .info-box .summary {
        margin-top: 2.275vh !important;
        font-size: 35px !important;
        width: 100%;
        /* height: calc(100% * 0.5638888888888889); */
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
    }
}