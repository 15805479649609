.booklist-mian {
    position: absolute;
    margin-top: 341px;
    width: calc(100% - 128px);
    display: grid;
    grid-auto-flow: row;
    grid-auto-rows: auto;
    grid-template-columns: repeat(auto-fill, 11vw);
    place-content: space-around;
    grid-gap: 20px;
    padding-left: 64px;
    padding-right: 64px;
}

.booklist-mian .bookcard-main {
    margin-top: 30px;
    width: 11vw;
}

.bookcard-main img {
    width: 100%;
    border-radius: 10px;
}

.bookcard-main .title {
    margin-top: 15px;
    text-align: center;
    font-size: 30px;
    font-weight: 400;
    text-overflow: -o-ellipsis-lastline;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
}

.bookcard-main .title_column_2 {
    text-overflow: -o-ellipsis-lastline;
    word-break: break-all;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden;
}

.booklist-mian .limit {
    width: 100%;
    height: 100px;
}

@media screen and (min-width:1800px) {
    .booklist-mian {
        position: absolute;
        margin-top: 341px;
        width: calc(100% - 128px);
        display: flex;
        flex-wrap: wrap;
        grid-gap: 20px;
        padding-left: calc(64px + 40px);
        padding-right: calc(64px - 40px);
    }

    .booklist-mian .bookcard-main {
        margin-right: 35px;
    }

    .booklist-mian-4 {
        position: absolute;
        margin-top: 341px;
        width: calc(100% - 143px);
        display: flex;
        flex-wrap: wrap;
        grid-gap: 20px;
        padding-left: calc(84px + 35px);
        padding-right: calc(64px - 40px);
    }

    .booklist-mian-4 .bookcard-main {
        margin-right: 35px;
    }
}

@media screen and (max-width:1100px) and (min-width:900px) {
    /* 书架大屏 */
    .booklist-mian {
        width: calc(100% - 40px);
        display: flex;
        flex-wrap: wrap;
        grid-gap: 15px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .booklist-mian .bookcard-main {
        margin-top: 40px !important;
        width: 25vw !important;
        margin-left: calc(20vw / 2);
    }

    .bookcard-main .title {
        margin-top: 10px;
        font-size: 34px !important;
    }

    .booklist-mian .bookcard-main[type="frist"] {
        margin-left: 0vw;
    }
}

@media screen and (max-width:900px) and (min-width:500px) {
    .booklist-mian {
        width: calc(100% - 40px);
        grid-auto-flow: row;
        grid-auto-rows: auto;
        grid-template-columns: repeat(auto-fill, 11vw);
        place-content: space-around;
        grid-gap: 15px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .booklist-mian .bookcard-main {
        margin-top: 10px;
        width: 11vw;
    }

    .bookcard-main .title {
        margin-top: 10px;
        font-size: 14px;
    }
}

@media screen and (max-width:500px) {
    .booklist-mian {
        width: calc(100% - 40px);
        grid-auto-flow: row;
        grid-auto-rows: auto;
        grid-template-columns: repeat(auto-fill, 25vw);
        place-content: space-around;
        grid-gap: 15px;
        padding-left: 20px;
        padding-right: 20px;
    }

    .booklist-mian .bookcard-main {
        margin-top: 10px;
        width: 25vw;
    }

    .bookcard-main .title {
        margin-top: 10px;
        font-size: 14px;
    }
}