body,
html {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.loading {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 99999999999;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
}

.loading .loader {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #2e789a;
    width: 100px;
    height: 100px;
    /* animation-name:load; */
    animation: load 2s linear infinite;
}

@keyframes load {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.canvas {
    position: absolute;
    top: 0;
}

.frame-common-box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.size-transition {
    transition: width 0.5s, height 0.5s;
}

.ring-light-open {
    border: 2px solid #ffffff;
    animation: ring-light-open 0.8s ease-in-out;
}

@keyframes ring-light-open {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    100% {
        transform: scale(1.5);
        opacity: 0;
    }
}

.ring-light-close {
    border: 2px solid #ffffff;
    animation: ring-light-close 0.4s;
}

@keyframes ring-light-close {
    0% {
        transform: scale(1.5);
        opacity: 0;
    }

    50% {
        transform: scale(1.5);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.border-1 {
    animation: border-1 5s linear infinite;
}

@keyframes border-1 {
    from {}

    to {
        transform: rotate(360deg);
    }
}

.border-2 {
    animation: border-2 6s linear infinite;
}

@keyframes border-2 {
    from {}

    to {
        transform: rotate(360deg);
    }
}

.border-3 {
    animation: border-3 5s linear infinite;
}

@keyframes border-3 {
    from {}

    to {
        transform: rotate(360deg);
    }
}

.border-4 {
    animation: border-4 7s linear infinite;
}

@keyframes border-4 {
    from {}

    to {
        transform: rotate(-360deg);
    }
}

.border-open {
    animation: border-open 1s ease-in-out;
    transform: scale(1);
}

@keyframes border-open {
    0% {
        transform: scale(1.5) rotate(180deg);
    }

    100% {
        transform: scale(1) rotate(0deg);
    }
}

.border-close {
    animation: border-close 1s ease-in-out;
    transform: scale(1);
}

@keyframes border-close {
    0% {
        transform: scale(1) rotate(0deg);
    }

    100% {
        transform: scale(1.5) rotate(180deg);
    }
}

.title-open {
    animation: title-open 1s;
}

@keyframes title-open {
    0% {
        position: absolute;
        margin-top: 100px;
        opacity: 0;
    }

    100% {
        position: absolute;
        margin-top: 0px;
        opacity: 1;
    }
}

.title-close {
    margin-top: 100px;
    animation: title-close 0.5s;
    opacity: 0;
}

@keyframes title-close {
    0% {
        position: absolute;
        margin-top: 0px;
        opacity: 1;
    }

    100% {
        position: absolute;
        margin-top: 150px;
        opacity: 0;
    }
}

.summary-open {
    animation: summary-open 1s;
}

@keyframes summary-open {
    0% {
        position: absolute;
        margin-top: -150px;
        opacity: 0;
    }

    100% {
        position: absolute;
        margin-top: 0px;
        opacity: 1;
    }
}

.summary-close {
    margin-top: -150px;
    animation: summary-close 0.5s;
    opacity: 0;
}

@keyframes summary-close {
    0% {
        position: absolute;
        margin-top: 0px;
        opacity: 1;
    }

    100% {
        position: absolute;
        margin-top: -150px;
        opacity: 0;
    }
}