.navigation-main {
    width: 100%;
    height: 11.71875vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff;
    position: relative;
    z-index: 10;
}

.navigation-main .back-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 13.125vw;
    flex-shrink: 0;
}

.back-btn .back-icon {
    width: 3.31484375vw;
    height: 3.31484375vw;
    flex-shrink: 0;
}

.back-btn .back-label {
    margin-left: 0.46875vw;
    font-size: 2.34375vw;
    color: rgb(107, 107, 107);
    flex-shrink: 0;
}

.navigation-main .marquee-main {
    margin-left: -10vw;
    width: 70vw;
}

.navigation-main .nav-main-title-text {
    font-size: 3.75vw;
    font-weight: 400;
    text-align: center;
    color: rgba(51, 51, 51, 1);
}

.navigation-main .nav-main-bottom {
    position: absolute;
    bottom: 0;
    height: 0.15625vw;
    width: 80%;
    background-color: rgba(232, 232, 232, 1);
}