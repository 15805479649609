.player-slider-main {
    margin-top: 3.28125vw;
    width: 100%;
    height: 3.125vw;
    margin-top: 1.4rem;
    display: flex;
    align-items: center;
}

.player-slider-main .in-total {
    position: absolute;
    width: 100%;
    height: 1.40625vw;
    background-color: rgba(105, 157, 223, 1);
    border-radius: 9px;
}

.player-slider-main .speed-progress {
    position: absolute;
    width: 0%;
    height: 1.40625vw;
    background-color: rgba(232, 232, 232, 1);
    border-radius: 9px;
}

.player-slider-main .slider-block {
    display: flex;
    align-items: center;
    position: absolute;
    left: 0%;
    height: 3.125vw;
    padding-left: 0.5vw;
    padding-right: 0.5vw;
    border-radius: 1.5625vw;
    font-size: 1.5625vw;
    /* line-height: 18px; */
    color: rgba(102, 102, 102, 1);
    background-color: rgba(232, 232, 232, 1);
}