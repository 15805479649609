.card-screen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll !important;
    overflow-x: hidden !important;
}

.card-screen::-webkit-scrollbar {
    display: none;
}