.power_container {
    position: relative;
    width: 100%;
    height: 100vh; //calc(177.7597402597403vw - 36.52597402597403vw - 22.72727272727273vw);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    // padding-top: 36.52597402597403vw;
    // padding-bottom: 22.72727272727273vw;
    display: flex;
    justify-content: center;
    user-select: none !important;
    -webkit-user-select: none !important;
    .power_header {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 12.0625rem;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .content {
        width: 83.92857142857143vw;
        height: calc(100vh - 13.0625rem - 8.625rem);
        position: relative;
        margin-top: 13.0625rem;
    }
    .power_footer{
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100vw;
        height: 7.625rem;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
}
